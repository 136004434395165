import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import SEO from 'src/components/SEO';
import { RootLayout } from 'src/layouts';

const transition = { duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] };

const thumbnailVariants = {
  initial: { scale: 0.9, opacity: 0 },
  enter: { scale: 1, opacity: 1, transition },
  exit: {
    scale: 0.5,
    opacity: 0,
    transition: { duration: 1.5, ...transition },
  },
};

const NotFoundPage = (props) => (
  <>
    <SEO title="404: Not found" />
    <motion.div variants={thumbnailVariants}>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </motion.div>
  </>
);

export default NotFoundPage;
